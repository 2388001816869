import React from 'react';
import Layout from '../components/layout';
import * as styles from './nos-engagements.module.scss';
import '../components/layout.scss';
import SEO from '../components/seo';
import engagementsQualiteMaitrisee from '../images/icons/engagements_qualité-maitrisée.svg';
import engagementsPrixCompetitifs from '../images/icons/engagements_prix-compétitifs.svg';
import engagementsDelaisMaitrises from '../images/icons/engagements_délais-respectés.svg';
import engagementsServicePersonnalise from '../images/icons/engagements_service-personnalisé.svg';
import stars from '../images/societe/stars.svg';

const EngagementsPages = () => (
  <Layout>
    <SEO title="Nos engagements" />
    <section>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">Nos engagements</h1>
            <p>
              Afin de vous satisfaire et de bâtir une collaboration pérenne,
              nous sommes particulièrement attentifs au respect des points
              suivants :
            </p>
          </div>
        </div>
      </div>
      <div className="large-container">
        <div className="mb0 row text-center">
          <div className="col-lg-2" />
          <div className="col-sm-6 col-lg-4">
            <div className={styles.engagement}>
              <div className={styles.engagementImg}>
                <img src={engagementsQualiteMaitrisee} />
              </div>
              <div className="card">
                <h2>Qualité maîtrisée</h2>
                <p>
                  Un <strong>suivi rigoureux</strong> des normes d’impression et
                  un
                  <strong> soin particulier</strong> accordé au contrôle du
                  produit fini vous garantissent une qualité à la hauteur de vos
                  attentes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className={styles.engagement}>
              <div className={styles.engagementImg}>
                <img src={engagementsPrixCompetitifs} />
              </div>
              <div className="card">
                <h2>Prix compétitifs</h2>
                <p>
                  Pour chacun de vos projets, nous{' '}
                  <strong>optimisons les coûts</strong> en choisissant la{' '}
                  <strong>technologie</strong> la mieux adaptée en fonction de
                  la quantité, du <strong>format</strong> et de la{' '}
                  <strong>matière</strong> à imprimer.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2" />
          <div className="col-lg-2" />
          <div className="col-sm-6 col-lg-4">
            <div className={styles.engagement}>
              <div className={styles.engagementImg}>
                <img src={engagementsDelaisMaitrises} />
              </div>
              <div className="card">
                <h2>Délais respectés</h2>
                <p>
                  Du devis à la livraison, en passant par la fabrication, nous
                  attachons une importance particulière au{' '}
                  <strong>respect des délais prédéfinis</strong>.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className={styles.engagement}>
              <div className={styles.engagementImg}>
                <img src={engagementsServicePersonnalise} />
              </div>
              <div className="card">
                <h2>Service personnalisé</h2>
                <p>
                  Nous avons construit notre histoire sur une forte culture
                  client : un interlocuteur toujours <strong>disponible</strong>
                  , des <strong>conseils</strong> pour vos projets, une gestion
                  efficace du <strong>SAV</strong> pour{' '}
                  <strong>100% de satisfaction</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`parallax ${styles.lesplus}`}>
      <div className="large-container">
        <div className="mb0 row">
          <div className="col-lg-2" />
          <div className=" col-md-8 col-lg-5">
            <div className="card bg-white">
              <h2 className="text-center">
                Les <span className={styles.plusSign}>+</span>
              </h2>
              <div className={styles.plus}>
                <div>
                  <span className={styles.number}>1</span>
                  <div className="card">
                    <h3>Réactivité dans la réponse aux devis</h3>
                    <span>
                      Entre 1 h et 6 h maximum <br />
                      (hors produits complexes)
                    </span>
                  </div>
                </div>
                <div>
                  <span className={styles.number}>2</span>
                  <div className="card">
                    <h3>
                      Délais de livraison
                      <br /> flexibles
                    </h3>
                    <span>
                      Livraison possible en 24h <br />
                      sur un large choix de produits
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-4 col-lg-3">
            <div
              className={`bg-medium-primary text-center ${
                styles.meilleurRapport
              }`}
            >
              <img src={stars} />
              <span className={styles.line1}>Meilleur rapport</span>
              <span className={styles.line2}>Qualité &bull; Prix &bull; </span>
              <span className={styles.line2}>Délais &bull; Service</span>
              <img src={stars} />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default EngagementsPages;
